<script setup lang="ts">
import slugify from '@sindresorhus/slugify'

defineProps<Props>()

const { myLayer } = useAppConfig()

interface Props {
  product: {
    id: number
    name: string
    primary_image_url: string
    brand_name: string
    model_name: string
    mileage?: number
    year?: number
    gear_type?: 'manuel' | 'automatisk' | null
    fuel_type?: 'benzin' | 'diesel' | 'el' | null
    electric_range?: number | null
  }
  fromPrice: number
  downpayment?: number
  kilometersPerYear?: number
  leasingPeriod?: number
  isKassebilFri?: boolean | null
}
</script>

<template>
  <ULandingCard
    :key="product.id"
    :to=" {
      path: myLayer.name === 'hellocar' ? `/brugte-biler/${slugify(product.brand_name)}/${slugify(product.model_name)}/${product.id}` : `/brugte-varebiler/${slugify(product.brand_name)}/${slugify(product.model_name)}/${product.id}`,
      query: {
        leasing_period: leasingPeriod ? leasingPeriod : undefined,
        kilometers_per_year: kilometersPerYear ? kilometersPerYear : undefined,
      },
    } "
    color="primary"
    class="transition-all hover:scale-[101%] hover:shadow-xl duration-300 cursor-pointer"
    :ui="{
      wrapper: 'relative group isolate rounded-3xl background-gradient ring-1 ring-gray-200 dark:ring-gray-800 before:hidden before:lg:block before:absolute before:-inset-[2px] before:h-[calc(100%+4px)] before:w-[calc(100%+4px)] before:z-[-1] before:rounded-[25px] flex-1 flex flex-col shadow',
      to: 'hover:ring-primary-500 dark:hover:ring-primary-400 transition-shadow duration-200',
      base: 'flex-1 flex flex-col h-full overflow-hidden',
      container: '',
      body: {
        padding: 'p-0 sm:p-0 block h-full',
        base: '',
        background: 'bg-white',
      },
      background: 'bg-white dark:bg-gray-900 hover:bg-opacity-90 dark:hover:bg-opacity-90 transition-[background-opacity]',
      ring: '',
      rounded: 'rounded-3xl',
      shadow: '',
      icon: {
        wrapper: 'mb-2 pointer-events-none',
        base: 'w-8 h-8 flex-shrink-0 text-gray-900 dark:text-white',
      },
      title: 'text-gray-900 dark:text-white text-base font-bold truncate',
      description: 'text-[15px] text-gray-500 dark:text-gray-400 mt-1',
    }"
  >
    <div class="absolute top-3 right-2 z-20">
      <UBadge
        v-if="product.electric_range" variant="subtle" :ui="{
          rounded: 'rounded-2xl',
        }" size="sm" color="emerald"
      >
        <Icon name="LightningDuotone" class="text-emerald-600 mr-1.5" size="1rem" /> <span>{{ product.electric_range }} km</span>
      </UBadge>
      <UBadge
        v-if="isKassebilFri" variant="subtle" :ui="{
          rounded: 'rounded-2xl',
        }" size="sm" color="emerald"
      >
        <Icon name="BoxCheckIcon" class="text-emerald-600 mr-1.5" size="1rem" /> <span>Kassebil Fri</span>
      </UBadge>
    </div>
    <div class="flex flex-col w-auto h-full justify-between">
      <!-- Top content -->
      <div class="h-auto w-full">
        <img
          v-if="product.primary_image_url"
          :src="useCloudinary(product.primary_image_url, {
            quality: 'auto',
            format: 'webp',
            resize: {
              height: 500,
              aspectRatio: '1:1',
              type: 'pad',
            },
            effect: {
              name: 'bgremoval',
            },
            background: 'auto:predominant',
          })"
          class="w-full hidden sm:inline min-w-full h-[200px] object-cover bg-primary-50 border-b border-secondary-200"
          :alt="`Brugt ${product.brand_name} ${product.model_name}`"
        >

        <img
          v-if="product.primary_image_url"
          :src="useCloudinary(product.primary_image_url, {
            quality: 'auto',
            format: 'webp',
            resize: {
              height: 500,
              aspectRatio: '3:1',
              type: 'pad',
            },
            effect: {
              name: 'bgremoval',
            },
            background: 'auto:predominant',
          })"
          class="w-full sm:hidden min-w-full h-[200px] object-cover bg-primary-50 border-b border-secondary-200"
          :alt="`Brugt ${product.brand_name} ${product.model_name}`"
        >
        <div class="px-4 pt-4">
          <p class="text-base font-regola-medium text-black">
            {{ product.brand_name }} {{ product.model_name }}
          </p>
          <p class="text-xs font-regola-book font-normal text-gray-600">
            {{ product.name }}
          </p>
        </div>
      </div>

      <!-- Bottom content -->
      <div class="pt-0 px-4 pb-4 w-full block justify-between h-auto">
        <UDivider class="my-2" />

        <div v-if="product.year || product.mileage" class="flex flex-wrap gap-2">
          <UBadge v-if="product.year" variant="soft">
            {{ product.year }}
          </UBadge>
          <UBadge v-if="product.mileage" variant="soft">
            {{ useFormatNumber(product.mileage) }} km.
          </UBadge>
          <UBadge v-if="product.fuel_type" variant="soft">
            {{ product.fuel_type }}
          </UBadge>
          <UBadge v-if="product.gear_type && product.gear_type === 'manuel'" variant="soft">
            manuelt gear
          </UBadge>
          <UBadge v-if="product.gear_type && product.gear_type === 'automatisk'" variant="soft">
            automatgear
          </UBadge>
        </div>

        <UDivider class="my-2" />

        <div class="text-base md:text-lg flex items-center justify-between font-regola-medium font-normal text-black">
          <p>
            {{ useFormatPrice(fromPrice) }}
          </p>
          <p class="text-gray-600 text-sm">
            pr.
            <span class="sm:hidden">mnd</span>
            <span class="hidden sm:inline">måned</span>
          </p>
        </div>
        <UDivider v-if="downpayment || kilometersPerYear || leasingPeriod" class="my-2" />
        <div v-if="downpayment" class="text-xs flex items-center text-gray-600 justify-between font-regola-book font-normal">
          <p>
            <em>
              Udbetaling
            </em>
          </p>
          <p class="text-gray-600">
            {{ useFormatPrice(downpayment) }}
          </p>
        </div>
        <div v-if="leasingPeriod" class="text-xs flex items-center text-gray-600 justify-between font-regola-book font-normal">
          <p>
            <em>
              Leasingperiode
            </em>
          </p>
          <p class="text-gray-600">
            {{ useFormatNumber(leasingPeriod / 12) }} år.
          </p>
        </div>

        <div v-if="kilometersPerYear" class="text-xs flex items-center text-gray-600 justify-between font-regola-book font-normal">
          <p>
            <em>
              Kilometer pr. år
            </em>
          </p>
          <p class="text-gray-600">
            {{ useFormatNumber(kilometersPerYear) }} km.
          </p>
        </div>
      </div>
    </div>
  </ULandingCard>
</template>
